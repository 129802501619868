import { AppLogo, Button, ListItem } from './Components';

import './App.scss'
import { useEffect, useState } from 'react';
import { globals } from './globals';
import { RiseLoader } from 'react-spinners';
import { usePostallcode } from './Hooks';

const dayOfWeekText = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

function App() {
  const [postallcode, setPostallCode] = useState('')
  const [disabled, setDisabled] = useState(true)
  const { auth, answer, loading, denied } = usePostallcode() // error

  useEffect(() => {
    setDisabled(postallcode.length !== 7)
  }, [postallcode])
  
  return (
    <div className="App">
      <div className="App__Wrapper">
        <div className="App__Left">
          <div className='App__Left__Icon'>
            <AppLogo style={{
              borderRadius: 8
            }} />
          </div>
          <div className="App__Left__Content">
            <h1>Demo Comercio</h1>
            <p>Bienvenido a Postall, esta es una aplicación de prueba para los comercios.</p>
            {(!answer && !denied) && <>
              <label htmlFor="postallcode">Ingresa tu Código Postall:</label>
              <input maxLength={7} defaultValue={postallcode} value={postallcode} onChange={(e) => {
                const val = `${e.target.value}`.toUpperCase().replace(/[0-9]/g, '').trim()
                if (val.length <= 7) {
                  setPostallCode(val)              
                }
              }} id="postallcode" placeholder='ej: GDOQMDK' />
            </>}
          </div>
          {(!answer && !denied) && <Button loading={loading} disabled={disabled} onClick={() => {
            auth(postallcode)
          }}>
            Autorizar
          </Button>}
        </div>
        <div className="App__Right">
          <div>
            <h2>Sobre tu disponibilidad</h2>
            {!answer ? <>
              <p>Recuerda que los datos compartidos requieren tu <strong>autorización</strong> y no almacenamos datos en nuestros servidores.</p>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
              }}>
                <ListItem><>Obtén información de <strong>tu dirección actualizada</strong> registrada en tu aplicación.</></ListItem>
                <ListItem><>Conoce la configuración vigente <strong>sobre tu disponibilidad</strong>.</></ListItem>
                <ListItem><>Simula una compra en un <strong>comercio ficticio</strong> para conocer el formato de autorizaciones.</></ListItem>
              </div>
            </> : <>
              {answer.CodeAuthHandler.address.map(address => {
                return <div>
                  <h1>{address.alias}</h1>
                  <h2>{address.information.city}, {address.information.country}</h2>
                  <small>{address.information.street} {address.information.number}</small>

                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                  {address.availability.map(availability => {
                    return <ListItem><><strong>{dayOfWeekText[availability.day]}</strong><br />{availability.schedule.map(schedule => <>{schedule.startAt} - {schedule.endAt}<br /></>)}</></ListItem>
                  })}
                  </div>
                </div>
              })}
            </>}
          </div>

          {(!answer) && <div className="App__Right__Footer">
            {!denied && <RiseLoader size={6} color={globals.primaryColor} /> }
            {!denied ? 'Esperando información desde tu celular...' : 'Rechaste el permiso, recarga la página y vuelve a intentarlo.'}
          </div>}
        </div>
      </div>
      <small>Postallcode.com - Todos los derechos reservados - 2024 &copy;</small>
    </div>
  );
}

export default App;
