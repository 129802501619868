import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width='1em'
        height='1em'
        fill="none"
        viewBox="0 0 573 573"
        {...props}
    >
        <path
            fill="#FFE800"
            d="m181.441 379.258 103.721 103.72 93.049-93.049-103.721-103.72-93.049 93.049ZM157.056 168.774l117.435 117.435 103.717-103.718L260.773 65.057 157.056 168.774Z"
            opacity={0.61}
            style={{
                mixBlendMode: "luminosity",
            }}
        />
        <path
            fill="#FFE800"
            d="M181.441 14.28c-49.396 49.395-60.311 118.564-24.383 154.492L260.775 65.055 195.72 0l-14.279 14.28ZM181.441 379.257c-49.398 49.398-49.398 129.484 0 178.882l14.279 14.279 89.441-89.44-103.72-103.721ZM274.49 286.209l103.721 103.72 103.717-103.717-103.72-103.72L274.49 286.209Z"
            style={{
                mixBlendMode: "luminosity",
            }}
        />
    </svg>
)
export default SvgComponent
