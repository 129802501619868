import './Button.scss'

interface ButtonProps {
    children: JSX.Element | string
    className?: string
    onClick: () => void
    disabled?: boolean
    loading?: boolean
}

const Button = ({ children, className, onClick, disabled, loading }: ButtonProps) => {
    if (disabled || loading) {
        className = `${className} Button__Disabled`
    }
    return <div className={`Button Button__${className}`} onClick={() => {
        if (!disabled || !loading) {
            onClick()
        }
    }}>
        {children}
    </div>
}

export default Button