import { useEffect, useState } from "react";

const GQL_PROTOCOL = {
  CONNECTION_INIT: 'connection_init',
  CONNECTION_ACK: 'connection_ack',
  CONNECTION_ERROR: 'connection_error',
  CONNECTION_KEEP_ALIVE: 'ka',
  START: 'subscribe',
  STOP: 'stop',
  CONNECTION_TERMINATE: 'connection_terminate',
  DATA: 'data',
  ERROR: 'error',
  COMPLETE: 'complete',
  MESSAGE: 'next'
}

const queryWs = (token: string) => `subscription CodeAuthHandler {
  CodeAuthHandler (uuid: "${token}") {
    status
    address
    availability
  }
}`

const query = `mutation CodeAccessRequest($CodeAccessRequestInput: CodeAccessRequestInput!) {
	CodeAccessRequest(input: $CodeAccessRequestInput) {
		success
		code
		message
		uuid
	}
}`;

interface Message<T> {
  id: string
  payload: {
    data: T
  }
  type: string
}

interface IAddress {
  alias: string
  availability: {
    day: number
    available: boolean
    schedule: {
      startAt: string
      endAt: string
    }[]
    timezone: string
  }[]
  gps: [number, number],
  information: {
    city: string
    country: string
    number: string
    street: string
  }
  uuid: string
}
interface IAvailabilityAnswer {
  CodeAuthHandler: {
    address: IAddress[]
    status: boolean
  }
}

export default function usePostallcode() {
  const [token, setToken] = useState<string>()
  const [ws, setWs] = useState<WebSocket>()
  const [ready, setReady] = useState<boolean>(false)
  const [answer, setAnswer] = useState<IAvailabilityAnswer>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [denied, setDenied] = useState<boolean>(false)

  useEffect(() => {
    if (token && token.length > 0 && !ws) {
      const webSocket = new WebSocket('wss://api.postallcode.com/graphql', 'graphql-transport-ws')

      webSocket.onopen = event => {
        console.log('onopen', event)
        webSocket.send(JSON.stringify({
          type: GQL_PROTOCOL.CONNECTION_INIT,
          // payload: {}
        }))
      }

      webSocket.onerror = event => {
        console.log('onerror', event)
      }

      webSocket.onclose = event => {
        console.log('close', event)
      }

      webSocket.onmessage = event => {
        const data = JSON.parse(event.data)
        console.log('onmessage', data.type, event)
        switch (data.type) {
          case GQL_PROTOCOL.CONNECTION_ACK: {
            console.log('success')
            setReady(true)
            break
          }
          case GQL_PROTOCOL.CONNECTION_ERROR: {
            console.error(data.payload)
            break
          }
          case GQL_PROTOCOL.CONNECTION_KEEP_ALIVE: {
            break
          }
          case GQL_PROTOCOL.DATA: {
            console.log(data.id, data.payload.errors, data.payload.data)
            break
          }
          case GQL_PROTOCOL.COMPLETE: {
            console.log('completed', data.id)
            break
          }
          case GQL_PROTOCOL.MESSAGE: {
            const message = JSON.parse(event.data) as Message<IAvailabilityAnswer>
            console.log(message)
            if (message.payload.data.CodeAuthHandler.status) {
              const parsedAddress = JSON.parse(message.payload.data.CodeAuthHandler.address as unknown as string) as IAddress[]

              setAnswer({
                CodeAuthHandler: {
                  ...message.payload.data.CodeAuthHandler,
                  address: parsedAddress
                }
              })

              setLoading(false)
            } else {
              setDenied(true)
            }
            break
          }
        }
      }


      setWs(webSocket)

      return () => {
        if (ws as unknown as WebSocket) {
          ws!.send(JSON.stringify({
            type: GQL_PROTOCOL.CONNECTION_TERMINATE
          }))
          ws!.close()
        }
      }
    }
    console.log('token', token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {

    if (ready && ws) {
      ws.send(JSON.stringify({
        type: GQL_PROTOCOL.START,
        id: `123456789-987654321`,
        payload: {
          query: queryWs(`${token}`),
          operationName: 'CodeAuthHandler',
          extensions: {}
        }
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready])

  const _makeRequest = (postallcode: string) => {
    const url = 'https://api.postallcode.com/graphql';

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          CodeAccessRequestInput: {
            apiKey: "65d1fcb4-cd55-434d-9b82-4136269df5af",
            postallcode
          }
        }
      })
    };
    return new Request(url, options);
  }

  const auth = async (code: string) => {
    try {
      setLoading(true)
      const request = _makeRequest(code)
      const result = await fetch(request)
      const data = await result.json();

      const payload = data.data.CodeAccessRequest
      if (payload.success) {
        setToken(payload.uuid)
      } else {
        setLoading(false)
        setError(true)
        console.error(data)
      }
    } catch (error) {
      setLoading(false)
      setError(true)
      console.error(error);
    }
  }

  return {
    auth,
    answer,
    loading,
    error,
    denied
  }
}