import { ListBullet } from "..";

type ListItemProps = {
    children: JSX.Element
}

export default function ListItem ({ children }: ListItemProps) {
    return <div className="ListItem">
        <ListBullet />
        <span>{children}</span>
    </div>
}