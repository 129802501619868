import { SVGProps } from "react"

const AppLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="3.5em"
        height="3.5em"
        fill="none"
        viewBox="0 0 1024 1024"
        {...props}
    >
        <path fill="url(#a)" d="M1024 0H0v1024h1024V0Z" />
        <path
            fill="#fff"
            d="M319.73 555.892v258.075h5.103c69.856 0 126.489-56.629 126.489-126.488V555.892H319.73ZM705.17 210.028H411.744c-50.819 0-92.015 41.196-92.015 92.014v39.577h345.864v312.282h39.577c50.814 0 92.009-41.196 92.009-92.01V302.042c0-50.818-41.195-92.014-92.009-92.014Z"
        />
        <path
            fill="#FFE800"
            d="M484.9 653.902h146.683V522.311H484.9v131.591ZM318.822 522.311H484.9V375.632H318.822v146.679Z"
            opacity={0.61}
        />
        <path
            fill="#FFE800"
            d="M226.821 395.824c0 69.855 41.192 126.484 92.001 126.484V375.63h-92.001v20.194ZM484.899 653.902c0 69.86 56.629 126.489 126.489 126.489h20.194V653.902H484.899ZM484.9 522.311h146.683V375.632H484.9v146.679Z"
        />
        <defs>
            <linearGradient
                id="a"
                x1={919.42}
                x2={-180.851}
                y1={942.25}
                y2={-219.688}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF5200" />
                <stop offset={1} stopColor="#FF8900" />
            </linearGradient>
        </defs>
    </svg>
)
export default AppLogo
